import React, { useState } from "react";
import { Drawer, Typography, IconButton, Button, Input, Spinner } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import rightArrow from "../../../../data/images/right_arrow.png";
import { repeatTransaction } from "../../../../helpers/AppTransactionApi";
import { Toaster, toast } from "react-hot-toast";

const SelectedTransactionDrawer = ({ openRight, setOpenRight, transactionData, setTransactionData, isAdmin }) => {
  const closeDrawerRight = () => setOpenRight(!openRight);
  const [processingBool, setProcessingBool] = useState(false);

  const handleRestartTransaction = (eventType) => {
    let bodyData;
    if (eventType === "classBooking") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id, calendarId: transactionData[0].calendarId }],
            rejectReason: [],
          },
          eventId: "classRosterBooking.created",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            classId: transactionData[0].classId ? transactionData[0].classId : null,
            classStartDateTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            classEndDateTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientUniqueId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientFirstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            clientLastName: transactionData[0].lastName ? transactionData[0].lastName : null,
            clientEmail: transactionData[0].email ? transactionData[0].email : null,
            clientPhone: transactionData[0].phone ? transactionData[0].phone : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionId: transactionData[0].id,
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            appoinmentStatus: "confirmed",
          },
        };
      }
    } else if (eventType === "classCancel" || eventType === "classCancelled") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          eventId: "classRosterBooking.cancelled",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            classId: transactionData[0].classId ? transactionData[0].classId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionId: transactionData[0].id,
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            appoinmentStatus: "cancelled",
          },
        };
      }
    } else if (eventType === "classUpdate") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        // {
        //   messageId: 'U9WYZhZBJS8mnnqQWn2v3b',
        //   eventId: 'classRosterBookingStatus.updated',
        //   eventSchemaVersion: 1,
        //   eventInstanceOriginationDateTime: '2023-11-07T14:45:54Z',
        //   eventInstanceOrigin: 'ClientsASPEventOrigin 7B343035',
        //   eventData: {
        //     siteId: 27616,
        //     locationId: 1,
        //     classId: 78217,
        //     classRosterBookingId: 839308,
        //     classDateTime: '2023-11-07T15:00:00Z',
        //     signedInStatus: 'SignedIn',
        //     staffId: 100000101,
        //     clientId: '100029609',
        //     clientUniqueId: 100029609,
        //     clientFirstName: 'Runglada',
        //     clientLastName: 'Srisomphet',
        //     clientEmail: 'topbunaz@gmail.com',
        //     clientPhone: '8064372939',
        //     itemId: 10609,
        //     itemName: 'VIP Unlimited - Monthly Recurring',
        //     itemSiteId: null,
        //     clientPassId: '614317'
        //   }
        // }
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          eventId: "classRosterBookingStatus.updated",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            classId: transactionData[0].classId ? transactionData[0].classId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            signedInStatus: transactionData[0].clientId ? transactionData[0].signedInStatus : null,
            clientEmail: transactionData[0].clientId ? transactionData[0].email : null,
            clientPhone: transactionData[0].clientId ? transactionData[0].phone : null,
            clientFirstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            clientLastName: transactionData[0].lastName ? transactionData[0].lastName : null,
          },
        };
      }
    } else if (eventType === "appointmentBooking") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionId: transactionData[0].id,
          userid: transactionData[0].userid,
          eventType: transactionData[0].eventType,
          eventId: "appointmentBooking.created",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            startDateTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            classEndDateTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientUniqueId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientFirstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            clientLastName: transactionData[0].lastName ? transactionData[0].lastName : null,
            clientEmail: transactionData[0].email ? transactionData[0].email : null,
            clientPhone: transactionData[0].phone ? transactionData[0].phone : null,
            sessionTypeId: transactionData[0].SessionTypeId ? transactionData[0].SessionTypeId : null,
            locationId: transactionData[0].locationId ? transactionData[0].locationId : null,
            appointmentId: transactionData[0].apptId ? transactionData[0].apptId : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            appoinmentStatus: "confirmed",
          },
        };
      }
    } else if (eventType === "appointmentCancel" || eventType === "appointmentCancelled") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionId: transactionData[0].id,
          userid: transactionData[0].userid,
          eventType: transactionData[0].eventType,
          eventId: "appointmentBooking.cancelled",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            appointmentId: transactionData[0].apptId ? transactionData[0].apptId : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            appoinmentStatus: "cancelled",
          },
        };
      }
    } else if (eventType === "clientCreated") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          eventType: transactionData[0].eventType,
          eventId: "client.created",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientUniqueId: transactionData[0].clientId ? transactionData[0].clientId : null,
            firstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            lastName: transactionData[0].lastName ? transactionData[0].lastName : null,
            email: transactionData[0].email ? transactionData[0].email : null,
            mobilePhone: transactionData[0].phone ? transactionData[0].phone : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
          },
        };
      }
    } else if (eventType === "membershipAdd") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          eventType: transactionData[0].eventType,
          eventId: "clientContract.created",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientUniqueId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientFirstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            clientLastName: transactionData[0].lastName ? transactionData[0].lastName : null,
            clientEmail: transactionData[0].email ? transactionData[0].email : null,
            clientContractId: transactionData[0].clientContractId ? transactionData[0].clientContractId : null,
            contractName: transactionData[0].contractName ? transactionData[0].membershipName : transactionData[0].membershipName ? transactionData[0].membershipName : null,
          },
        };
      } else if (transactionData[0].senderApp === "ghl" && transactionData[0].receiverApp === "mb") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          secret_key: transactionData[0].secret_key ? transactionData[0].secret_key : null,
          mb_id: transactionData[0].mb_id ? transactionData[0].mb_id : null,
          ghl_id: transactionData[0].ghl_id ? transactionData[0].ghl_id : null,
          eventType: transactionData[0].eventType,
          "Client Id": transactionData[0].clientId ? transactionData[0].clientId : null,
          contact_id: transactionData[0].contact_id ? transactionData[0].contact_id : null,
          first_name: transactionData[0].firstName ? transactionData[0].firstName : null,
          last_name: transactionData[0].lastName ? transactionData[0].lastName : null,
          email: transactionData[0].email ? transactionData[0].email : null,
          phone: transactionData[0].phone ? transactionData[0].phone : null,
          tags: transactionData[0].tags ? transactionData[0].tags : [],
          promocode: transactionData[0].promocode ? transactionData[0].promocode : null,
          calendar: {
            id: transactionData[0].calendarId ? transactionData[0].calendarId : null,
            selectedTimezone: transactionData[0].selectedTimezone ? transactionData[0].selectedTimezone : null,
            startTime: transactionData[0].startDate ? transactionData[0].startDate : null,
            endTime: transactionData[0].endDate ? transactionData[0].endDate : null,
            appoinmentStatus: "confirmed",
          },
        };

        console.log({ startTime: transactionData[0].startDate ? transactionData[0].startDate : null, endTime: transactionData[0].endDate ? transactionData[0].endDate : null });
      }
    } else if (eventType === "membershipCancel") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          userid: transactionData[0].userid,
          eventType: transactionData[0].eventType,
          eventId: "clientContract.updated",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            clientContractId: transactionData[0].clientContractId ? transactionData[0].clientContractId : null,
            isAutoRenewing: transactionData[0].isAutoRenewing ? transactionData[0].isAutoRenewing : false,
          },
        };
      }
    } else if (eventType === "clientUpdate") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          eventId: "client.updated",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            clientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            email: transactionData[0].clientId ? transactionData[0].email : null,
            mobilePhone: transactionData[0].clientId ? transactionData[0].phone : null,
            firstName: transactionData[0].firstName ? transactionData[0].firstName : null,
            lastName: transactionData[0].lastName ? transactionData[0].lastName : null,
          },
        };
      }
    } else if (eventType === "clientSale") {
      if (transactionData[0].senderApp === "mb" && transactionData[0].receiverApp === "ghl") {
        bodyData = {
          senderApp: transactionData[0].senderApp,
          receiverApp: transactionData[0].receiverApp,
          transactionData: {
            transactionIds: [{ transactionId: transactionData[0].id, ghl_id: transactionData[0].ghl_id }],
            rejectReason: [],
          },
          eventId: "clientSale.created",
          eventData: {
            siteId: transactionData[0].siteId ? transactionData[0].siteId : null,
            purchasingClientId: transactionData[0].clientId ? transactionData[0].clientId : null,
            items: JSON?.parse(transactionData[0].clientSale_items) || null,
          },
        };
      }
    }
    setProcessingBool(true);
    let toastBox = toast;
    const repeatTransactionPromise = repeatTransaction(bodyData);
    repeatTransactionPromise
      .then(
        (data) => {
          if (
            eventType === "membershipAdd" ||
            eventType === "classUpdate" ||
            eventType === "classCancel" ||
            eventType === "clientUpdate" ||
            eventType === "clientSale" ||
            eventType === "membershipCancel" ||
            eventType === "contractUpdate" ||
            eventType === "clientCreated" ||
            eventType === "classBooking"
          ) {
            if (data.statusUpdate === "finished") {
              setTransactionData([{ ...transactionData[0], status: "finished", errorCode: null, errorMessage: null }]);
            } else if (data.statusUpdate === "error") {
              setTransactionData([{ ...transactionData[0], status: "error", errorCode: data.code, errorMessage: data?.reason ? JSON.stringify(data?.reason) : data?.msg }]);
            } else if (data.statusUpdate === "skipped") {
              setTransactionData([{ ...transactionData[0], status: "skipped", errorCode: null, errorMessage: data.msg }]);
            }
          } else {
            if (data.msg === "success") {
              setTransactionData([{ ...transactionData[0], status: "finished", errorCode: null, errorMessage: null }]);
            } else if (data.msg === "failed") {
              setTransactionData([{ ...transactionData[0], status: "error", errorCode: data.error, errorMessage: data.errorMessage }]);
            }
          }
        },
        (data) => {
          toast.error(`${data.msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((data) => {
        toast.error(`${data.msg}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setProcessingBool(false);
      });
  };

  return (
    <React.Fragment>
      <Drawer size={700} placement="right" open={openRight} onClose={closeDrawerRight} className="p-4">
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        <div className="mb-6 ml-6 flex items-center justify-between">
          <Typography variant="h4" color="blue-gray">
            Event Details
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawerRight}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        {openRight && (
          <div className="px-8">
            <div className="mt-6 flex items-center justify-between">
              <Typography variant="h5" color={transactionData[0].status === "error" ? "red" : transactionData[0].status === "finished" ? "green" : transactionData[0].status === "skipped" ? "orange" : "black"}>
                Status: {transactionData[0].status} {transactionData[0].status === "error" && `[ ${transactionData[0].errorCode} ]`}
              </Typography>
              <Button
                type="button"
                onClick={() => {
                  handleRestartTransaction(transactionData[0].eventType);
                }}
              >
                {processingBool ? <Spinner /> : "Restart"}
              </Button>
            </div>
            <div className="mt-2">Error message: {transactionData[0].errorMessage !== null ? transactionData[0].errorMessage : "N/A"}</div>
            <hr className="mt-6" />
            <div className="mt-6 flex items-center justify-evenly text-2xl">
              {transactionData[0].senderApp === "ghl" ? "Go High Level" : transactionData[0].senderApp === "mb" ? "Mindbody" : transactionData[0].senderApp === "mt" ? "MarianaTek" : transactionData[0].senderApp}
              <img src={rightArrow} width={110} alt="logo" />
              {transactionData[0].receiverApp === "ghl" ? "Go High Level" : transactionData[0].receiverApp === "mb" ? "Mindbody" : transactionData[0].receiverApp === "mt" ? "MarianaTek" : transactionData[0].receiverApp}
            </div>
            <div className="w-full border border-brown-50 p-6 mt-6 rounded-lg">
              <div className="grid grid-cols-2 gap-3">
                <Input label="Event Type" size="lg" value={`${transactionData[0].eventType}`} readOnly />
                <Input label="Event ID" size="lg" value={`${transactionData[0].transactionId}`} readOnly />
              </div>
              {isAdmin && (
                <div className="grid grid-cols-2 gap-3 mt-6">
                  <Input label="User name" size="lg" value={`${transactionData[0].userName}`} readOnly />
                  <Input label="User email" size="lg" value={`${transactionData[0].userEmail}`} readOnly />
                </div>
              )}

              {transactionData[0].eventType === "clientCreated" && (
                <div className="mt-6">
                  <div className="grid grid-cols-2 gap-3">
                    <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                    <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                    <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                  </div>
                </div>
              )}
              {transactionData[0].eventType === "clientSale" && (
                <div className="mt-6">
                  <div className="grid grid-cols-2 gap-3">
                    <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                    <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    {JSON?.parse(transactionData[0]?.clientSale_items || null) &&
                      JSON?.parse(transactionData[0]?.clientSale_items)?.length !== 0 &&
                      JSON?.parse(transactionData[0]?.clientSale_items)?.map((element, index) => (
                        <>
                          <Input label={`Item name ${index + 1}`} size="lg" className="col-span-1" value={`${element?.name ? element?.name : "unavailable"}`} readOnly />
                          <Input label={`Item type ${index + 1}`} size="lg" className="col-span-1" value={`${element?.type ? element?.type : "unavailable"}`} readOnly />
                          <div className="col-span-2 w-2/4 mx-auto">
                            <Input label={`Amount paid ${index + 1}`} size="lg" value={`${element?.amountPaid ? element?.amountPaid : "unavailable"}`} readOnly />
                          </div>
                        </>
                      ))}
                  </div>

                  {/* <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                    <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                  </div> */}
                </div>
              )}
              {transactionData[0].eventType === "classBooking" && (transactionData[0].receiverApp === "mb" || transactionData[0].senderApp === "mb") && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Class ID" size="lg" value={`${transactionData[0].classId ? transactionData[0].classId : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                      <Input label="End Date and Time" size="lg" value={`${transactionData[0].endDate ? transactionData[0].endDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Calendar ID" size="lg" value={`${transactionData[0].calendarId ? transactionData[0].calendarId : "unavailable"}`} readOnly />
                      <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {transactionData[0].eventType === "classBooking" && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Class ID" size="lg" value={`${transactionData[0].classId ? transactionData[0].classId : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                      <Input label="End Date and Time" size="lg" value={`${transactionData[0].endDate ? transactionData[0].endDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Calendar ID" size="lg" value={`${transactionData[0].calendarId ? transactionData[0].calendarId : "unavailable"}`} readOnly />
                      <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {(transactionData[0].eventType === "classCancel" || transactionData[0].eventType === "classCancelled") && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Class ID" size="lg" value={`${transactionData[0].classId ? transactionData[0].classId : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                      <Input label="End Date and Time" size="lg" value={`${transactionData[0].endDate ? transactionData[0].endDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {transactionData[0].eventType === "classUpdate" && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Class ID" size="lg" value={`${transactionData[0].classId ? transactionData[0].classId : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                      <Input label="End Date and Time" size="lg" value={`${transactionData[0].endDate ? transactionData[0].endDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Appointment status" size="lg" value={`${transactionData[0].signedInStatus ? transactionData[0].signedInStatus : "unavailable"}`} readOnly />
                      <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {transactionData[0].eventType === "appointmentBooking" && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Location ID" size="lg" value={`${transactionData[0].locationId ? transactionData[0].locationId : "unavailable"}`} readOnly />
                      <Input label="Session ID" size="lg" value={`${transactionData[0].SessionTypeId ? transactionData[0].SessionTypeId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {(transactionData[0].eventType === "appointmentCancel" || transactionData[0].eventType === "appointmentCancelled") && (
                <>
                  <div className="mt-6">
                    <div className="grid grid-cols-2 gap-3">
                      <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                      <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                      <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                      <Input label="Start Date and Time" size="lg" value={`${transactionData[0].startDate ? transactionData[0].startDate : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Location ID" size="lg" value={`${transactionData[0].locationId ? transactionData[0].locationId : "unavailable"}`} readOnly />
                      <Input label="Session ID" size="lg" value={`${transactionData[0].SessionTypeId ? transactionData[0].SessionTypeId : "unavailable"}`} readOnly />
                    </div>
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Appointment ID" size="lg" value={`${transactionData[0].apptId ? transactionData[0].apptId : "unavailable"}`} readOnly />
                    </div>
                  </div>
                </>
              )}
              {transactionData[0].eventType === "membershipAdd" && (transactionData[0].receiverApp === "mb" || transactionData[0].senderApp === "mb") && (
                <div className="mt-6">
                  {console.log(transactionData[0])}
                  <div className="grid grid-cols-2 gap-3">
                    <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                    <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                    <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    <Input label="Membership name" size="lg" value={`${transactionData[0].membershipName ? transactionData[0].membershipName : "unavailable"}`} readOnly />
                  </div>
                  {transactionData[0].senderApp === "ghl" && (
                    <div className="mt-6 grid grid-cols-2 gap-3">
                      <Input label="Promocode" size="lg" value={`${transactionData[0].promocode ? transactionData[0].promocode : "unavailable"}`} readOnly />
                    </div>
                  )}
                </div>
              )}
              {transactionData[0].eventType === "clientUpdate" && (
                <div className="mt-6">
                  <div className="grid grid-cols-2 gap-3">
                    <Input label="First name" size="lg" value={`${transactionData[0].firstName ? transactionData[0].firstName : "unavailable"}`} readOnly />
                    <Input label="Last name" size="lg" value={`${transactionData[0].lastName ? transactionData[0].lastName : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Email" size="lg" value={`${transactionData[0].email ? transactionData[0].email : "unavailable"}`} readOnly />
                    <Input label="Phone" size="lg" value={`${transactionData[0].phone ? transactionData[0].phone : "unavailable"}`} readOnly />
                  </div>
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                    <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                  </div>
                </div>
              )}
              {transactionData[0].eventType === "membershipCancel" && (
                <div className="mt-6">
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                  </div>
                </div>
              )}
              {transactionData[0].eventType === "contractUpdate" && (
                <div className="mt-6">
                  <div className="mt-6 grid grid-cols-2 gap-3">
                    <Input label="Site ID" size="lg" value={`${transactionData[0].siteId ? transactionData[0].siteId : "unavailable"}`} readOnly />
                    <Input label="Client ID" size="lg" value={`${transactionData[0].clientId ? transactionData[0].clientId : "unavailable"}`} readOnly />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default SelectedTransactionDrawer;
